import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout } from "components";
import { DaegwanInfo } from "containers";

const DaegwanPage = () => {
  const data = useStaticQuery(query);
  return (
    <Layout>
      <DaegwanInfo data={data} />
    </Layout>
  );
};

export default DaegwanPage;

const query = graphql`
  query {
    allStrapiDaegwan {
      nodes {
        carousel_img {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        seminar_room_desc
        seminar_room_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        music_room_desc
        music_room_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    strapiDaegwan {
      music_room_application {
        localFile {
          publicURL
        }
      }
      seminar_room_application {
        localFile {
          publicURL
        }
      }
    }
  }
`;
